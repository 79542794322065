define("discourse/plugins/discourse-plugin-slider-banner/initializers/slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'slider-banner',
    initialize() {
      var left_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="42.828" viewBox="0 0 22.414 42.828"><path id="chevron-left" d="M29,46,9,26,29,6" transform="translate(-8 -4.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>';
      var right_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="42.828" viewBox="0 0 22.414 42.828"><path id="chevron-right" d="M29,46,9,26,29,6" transform="translate(30.414 47.414) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>';
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.addedNodes && mutation.addedNodes.length > 0) {
            const hasClass = [].filter.call(mutation.addedNodes, el => el.classList && el.classList.contains('js-banner-slick'))[0];
            if (hasClass) {
              $(hasClass).slick({
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                dots: true,
                adaptiveHeight: true,
                autoplay: true,
                autoplaySpeed: 9000,
                prevArrow: '<span class="slick-prev">' + left_icon + '</span>',
                nextArrow: '<span class="slick-next">' + right_icon + '</span>'
              });
            }
          }
        });
      });
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true
      });
    }
  };
});